import React from 'react'
import { graphql } from 'gatsby'
import Page from '../components/page'
import Seo from '../components/seo'
import Layout from '../components/layout'
import toPlainText from '../components/utils/toPlainText'

const PageTemplate = ({data}) => {
  const page = data && data.page
  let plainText
  if (page._rawContent) {
    const textBlock = page._rawContent.filter(c => c._type === 'textSection')
    if (textBlock.length > 0) {
      const firstBlock = textBlock[0].text
      plainText = toPlainText(firstBlock).substring(0,160).trim().replace(/\s+/g, ' ');
    }
  }
  return (
    <Layout hideHeaderAndFooter={page.hideHeaderAndFooter}>
      {page && <Seo pageTitle={page.title || 'Untitled'} pageDescription={plainText}/>}
      {page && <Page {...page} />}
    </Layout>
  )
}

export const query = graphql`
  query PageTemplateQuery($id: String!) {
    page: sanityPage(id: { eq: $id }) {
      id
      title
      slug {
        current
      }
      _rawContent(resolveReferences: {maxDepth: 1000})
      pageHeader
      hideHeaderAndFooter
      bannerImage {
        asset {
          gatsbyImageData
        }
        alt
      }
      _rawButtons(resolveReferences: {maxDepth: 1000})
    }
  }
`

export default PageTemplate
